const FrontCameraError = ({ back }) => {
    return (
        <div className="h-full z-20 absolute w-full top-0 bg-black/[0.3] backdrop-blur-sm flex items-center justify-center">
            <div className="bg-white w-11/12 rounded-md p-4 md:p-8 md:w-2/3 md:text-lg ">
                <h1 className=" text-xl font-bold md:text-3xl">Masalah akses kamera</h1>

                <p className="ms-2 mt-3"><span className="me-1"> &#8226;</span> Kami tidak dapat mengakses kamera depan Anda.</p>
                <p className="ms-2"><span className="me-1"> &#8226;</span>Kamera belakang Anda saat ini aktif</p>

                <p className="mt-3">Apakah Anda ingin mencoba lagi dengan selfie?</p>
                <div className="flex mt-4">
                    <button
                        type="button"
                        className="w-full bg-black text-white py-2 me-3 rounded md:py-3 md:text-xl"
                        onClick={() => { back(false) }}>Lanjutkan</button>
                    <button
                        type="button"
                        className="w-full bg-black text-white py-2 rounded md:py-3 md:text-xl"
                        onClick={() => window.location.reload()}>
                        Coba Ulang Selfie
                    </button>
                </div>

            </div>
        </div>
    )
}

export default FrontCameraError