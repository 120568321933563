import { useState } from "react";
import fairStatus from "../../assets/bernImages/fair.jpg";
import goodStatus from "../../assets/bernImages/good.jpg";
import poorStatus from "../../assets/bernImages/Poor.jpg";
import infoIcon from "../../assets/Icons/info.png";
import HealthPopup from "../ScoreCard/healthPopup";
import { fontStyle, fontWeight, buttonStyle } from "../../Utils/tpscanStyle";
const TpInfo = () => {
  const teethHealth = JSON.parse(localStorage.getItem("tpscore"));

  const status = [
    { type: "cavity", title: "Dental Cavity", img: goodStatus },
    { type: "tartar", title: "Plaque Buildup", img: fairStatus },
    { type: "gum_recession", title: "Gum Recession", img: poorStatus },
    { type: "gum_swelling", title: "Gum Swelling", img: poorStatus },
    {
      type: "gaps_poorly_aligned_teeth",
      title: "Gaps/CrookedTeeth",
      img: poorStatus,
    },
  ];

  const [popup, setPopup] = useState(false);
  const [popupType, setPopupType] = useState();
  const [contentType, setContentType] = useState();

  const openPopup = (data) => {
    setPopupType(data.title);
    setContentType(data.type)
    setPopup(true);
  };

  const Health = (props) => {
    const { data } = props;
    let text = "";
    let imgSrc = "";

    if (data.type === "cavity") {
      if (teethHealth.cavity_score == 0) {
        text = "";
        imgSrc = goodStatus;
      } else {
        text =
          "Untreated dental cavities can lead to severe pain and infections";
        imgSrc = poorStatus;
      }
    }
    if (data.type === "gaps_poorly_aligned_teeth") {
      if (teethHealth.gaps_poorly_aligned_teeth) {
        text =
          "Severe misalignment can lead to jaw pain and significant dental problems";
        imgSrc = poorStatus;
      } else {
        text =
          "Untreated dental cavities can lead to severe pain and infections";
        imgSrc = fairStatus;
      }
    }
    if (data.type === "tartar") {
      if (teethHealth.tartar_score == 0) {
        text = "";
        imgSrc = goodStatus;
      } else if (teethHealth.tartar_score <= 5) {
        text = "Moderate plaque buildup can lead to gingivitis if not treated";
        imgSrc = fairStatus;
      } else {
        text = "Severe plaque buildup can cause tooth decay and gum disease";
        imgSrc = poorStatus;
      }
    }
    if (data.type === "gum_recession") {
      if (teethHealth.gum_recession == 0) {
        text = "";
        imgSrc = goodStatus;
      } else if (teethHealth.gum_recession <= 5) {
        text = "Early gum recession can cause tooth sensitivity";
        imgSrc = fairStatus;
      } else {
        text =
          "Advanced gum recession can expose tooth roots and lead to tooth loss";
        imgSrc = poorStatus;
      }
    }
    if (data.type === "gum_swelling") {
      if (teethHealth.gum_swelling == 0) {
        text = "";
        imgSrc = goodStatus;
      } else if (teethHealth.gum_swelling <= 5) {
        text = "Mild gum swelling can be a sign of gingivitis";
        imgSrc = fairStatus;
      } else {
        text =
          "Severe gum swelling can indicate serious gum disease and infection";
        imgSrc = poorStatus;
      }
    }

    return (
      <div>
        <div className="my-2  mx-5 flex justify-start items-center">
          <div className="my-1   ">
            <span
              className="my-1 text-base"
              style={{ ...fontWeight, ...fontStyle, fontSize: "16px" }}
            >
              {data.title}
            </span>
            <button
              type="button"
              className="mb-1 mx-2"
              onClick={() => {
                openPopup(data);
              }}
            >
              <img src={infoIcon} alt="info" className="h-3" />
            </button>
          </div>
          <div className="ms-auto  ">
            {imgSrc && (
              <img src={imgSrc} alt="status" className="h-5 me-1 mt-2" />
            )}
          </div>
        </div>
        <div className="mx-5 opacity-75" style={{...fontStyle}}>{text}</div>
      </div>
    );
  };

  return (
    <>
      {status.map((eachStatus, i) => (
        <Health key={i} data={eachStatus} />
      ))}
      {popup && (
        <HealthPopup popupType={popupType} contentType={contentType} data={"data"} setPopup={setPopup} />
      )}
    </>
  );
};

export default TpInfo;