import React, { useState } from "react";
import Lottie from "lottie-react";
import teethAnimation from "../../assets/animations/Animation - bearn.json";
import close from "../../assets/Icons/close.png";
import {
  fontStyle,
  fontWeight,
} from "../../Utils/tpscanStyle";
import { postBookAppointment } from "../../Services/APIs/annotation";

const BookPopup = ({ closePopup }) => {
  const [bookAppointmentStatus, setBookAppointmentStatus] = useState(
    "Book An Appointment"
  );
  const uid=JSON.parse(localStorage.getItem("usertpi")).usertpi 

  const bookAppointment = async () => {
    setBookAppointmentStatus("Loading...");
    await postBookAppointment()
      .then((res) => {
        setTimeout(() => {
          setBookAppointmentStatus("Redirecting to book an appoitment");
        }, 2500);
        setBookAppointmentStatus("Book An Appointment");
      })
      .catch((err) => {
        setBookAppointmentStatus("Book An Appointment");
      });
  };
  return (
    <div
      className=" fixed top-0 left-0 h-full w-full bg-black bg-opacity-65 flex justify-center items-center z-50"
      style={{ fontFamily: "Open Sans, sans-serif" }}
    >
      <div className="bg-white w-11/12 py-3 px-4 rounded-xl flex flex-col">
        <button
          type="button"
          onClick={() => {
            closePopup(false);
          }}
          className=" self-end mt-1 my-2"
        >
          <img src={close} className="w-6 h-6" alt="close" />
        </button>
        <Lottie animationData={teethAnimation} />
        <p className=" text-xl font-bold text-center mt-3 mb-2">Great Job!</p>
        <p className="text-sm tex text-center" style={{ color: "#A3ABBB" }}>
          You've successfully completed your dental scan. Would you like to book
          an appointment with a dental professional?
        </p>

        <div>
        
            <button
              type="button"
              className="w-full text-sm font-semibold  text-white py-3 rounded-full my-3"
              style={{ background: "#13D022" }}
              onClick={() => {
                // bookAppointment();
              }}
            >
               <a
                    href={`https://staging.smilescheduler.com/6658b278ba58106b97078212/login?session_id=${uid}`}                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "inherit", fontFamily : 'Open Sans, sans-serif'  }}
                > 
              BOOK APPOINTMENT
              </a>
            </button>
        </div>
      </div>
    </div>
  );
};

export default BookPopup;