import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FLandingPage from './landingPage';
import FScan from './scan';
import FThankYou from './thank-you';
import PageNotFound from '../PageNotFound';


const Selfie = () => {
    return (
        <div className='h-full'>
            <Routes>
                <Route path="/" element={<FLandingPage />} />
                <Route path="scan" element={<FScan />} />
                <Route path="thanks" element={<FThankYou />} />
                <Route path="*" element={<PageNotFound />} />

            </Routes>
        </div>
    )
}

export default Selfie