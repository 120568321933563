
import { useNavigate } from "react-router-dom"
import stars from "../../../assets/illustrations/backgorund-stars.png"
import teeth from "../../../assets/illustrations/teeth.png"
import twoStars from "../../../assets/illustrations/two stars.png"

const PageNotFound = () => {

    const navigate = useNavigate()
    const uid = JSON.parse(localStorage.getItem("usertpi") || '{}').usertpi;

    const doNavigate = () => {
       navigate('/pg')
    }


    return (
        <div className="h-full w-full flex flex-col justify-end">
            <img src={stars} alt="stars" className="absolute top-0 w-full" />
            <img src={teeth} alt="stars" className="fixed top-16 left-10 saturate-50 " />
            <div className="h-1/2 flex flex-col justify-between px-8">
                <div>
                    <img src={twoStars} alt="stars" className="h-10" />
                    <p className=" text-4xl font-medium">Oops!</p>
                    <p className=" text-4xl font-medium mt-2">Page Not Found.</p>
                    <p className=" opacity-90 text-base font-light mt-4">It looks like you've stumbled upon a missing page.</p>
                </div>
                <p className=" text-base font-light mb-16">
                    Go back to the
                    <button
                        className="font-semibold underline px-2"
                        onClick={doNavigate}> Home Page </button>
                    .</p>
            </div>
        </div>
    )
}

export default PageNotFound