import React, { useState } from "react"
import { sendWhatsappMessage } from "../../Services/APIs/report"

const Whatsapp = () => {
    const [number, setNumber] = useState()
    const [report_file, seTfile] = useState("")

    const handleChange = (e) => {
        setNumber(e.target.value)

    }

    const handleChangeFile = (e) => {
        seTfile(e.target.value)

    }

    const sendMessage = () => {
        sendWhatsappMessage({ ph_no: number, report_file }).then((res) => {
            console.log("successfull")
            console.log(res)
        }).catch(err => {
            console.log(err)
            console.log(err)
        })
    }

    return (
        <div className="h-full flex flex-col justify-center items-center">
            <input type="number" className="border border-black mb-5 p-2 " onChange={handleChange} placeholder="type your number" />
            <input type="text" className="border border-black mb-5 p-2 " onChange={handleChangeFile} placeholder="paste file link" />

            <button className="bg-green-600 text-white px-5 py-3 rounded" onClick={sendMessage}>send whatsapp message</button>
        </div>
    )
}

export default Whatsapp