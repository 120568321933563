import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Shoot from "./shoot"

const SingleScan = () => {
    const navigate = useNavigate()

    useEffect(() => {
        if (!localStorage.getItem("usertpi") || localStorage.getItem("isScanCompleted") || !localStorage.getItem("template")) {
            navigate("/ipad")
        }

    }, [])

    return (
        <Shoot activeImageSide={0} setToothPicker={() => { }} quickScan={true} />
    )
}

export default SingleScan