import front from "../../../assets/demoImages/front.png"
import upperJaw from "../../../assets/demoImages/upper-reversed.png"
import lowerJaw from "../../../assets/demoImages/lower.png"
import left from "../../../assets/demoImages/left.png"
import right from "../../../assets/demoImages/right.png"

const steps = [
    {
        title: "正面",
        image: front,
        tips: ["歯の正面が映るように円の中に合わせてください", "軽く口を開けて、上下の歯にスペースをあけてください"]
    },
    // {
    //     title: "Left side",
    //     image: right,
    //     tips: ["Pull the corner of your mouth as back as possible"],
    // },
    // {
    //     title: "Right side",
    //     image: left,
    //     tips: ["Pull the corner of your mouth as back as possible"]
    // },
    // {
    //     title: "Lower jaw",
    //     image: lowerJaw,
    //     tips: ["Open your mouth tilt your head downwards", "Fit your teeth within the template."]
    // },
    // {
    //     title: "Upper jaw",
    //     image: upperJaw,
    //     tips: ["Open your mouth tilt your head backwards", "Fit your teeth within the template."]
    // }
]


export default steps
