import axios from "axios"
import { API_HOST } from "../BaseUrl"
import client, { headers } from "./config"

export const downloadPdf = (data) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `report-pdf-download`, data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}
export const downloadPdfForBearn = (data) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `report-pdf-download-for-bearn`, data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const readReports = () => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.get(API_HOST + `report`, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}

export const getScore = () => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.get(API_HOST + `latest-score`, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)

        }
    })
}

export const sendWhatsappMessage = (data) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + "test/check-whatsapp", data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }
    })
}

export const sendFileToWhatsapp = (data) => {
    const updatedHeaders = { ...headers }
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.post(API_HOST + "share-report/whatsapp", data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }
    })
}


export const downloadPdfForPg = (data) => {
    const updatedHeaders = { ...headers, Authorization: localStorage.getItem("authToken") }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST + `report-pdf-download`, data, { headers: updatedHeaders })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)

        } catch (error) {
            reject(error)
        }
    })
}