import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import welcomeTeeth from "../../../assets/illustrations/welcomeTeeth.png";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
import pepsodent_logo from "../../../assets/unilever/Pepsodent Logo.png"
import { generateRandomAlphaNumeric } from "../../../Utils/alphaNumeric";



const LandingPage = () => {

    const navigate = useNavigate()
    const storedUid = localStorage.getItem("usertpi")
    const initialId = storedUid ? JSON.parse(storedUid).usertpi : ''
    const [uid, setUid] = useState(initialId)
    const { images } = useSelector((state) => state.fullScan);
    const [isInvalidUrl, setInvalidUrl] = useState(false)

    localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "unilever" }));
    localStorage.setItem("isAutomated", true)
    localStorage.removeItem("isUnileverIpad")

    useEffect(() => {
        !localStorage.getItem("reloaded") && reload()
        if (!uid || uid.split("_").length !== 2) {
            setInvalidUrl(true)
        }

    }, [])

    const reload = () => {
        localStorage.setItem("reloaded", true)
        window.location.reload()
    }

    const generateInfo = () => {
        const randomString = generateRandomAlphaNumeric(2);
        const timestamp = Date.now().toString();
        const randomString2 = generateRandomAlphaNumeric(1)
        const unique = randomString + timestamp + randomString2
        // localStorage.setItem("usertpi", JSON.stringify({ usertpi: unique }));
        localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "unilever" }));
        setUid(unique)
    }

    const openIssues = () => {
        navigate("looking-for")
    }

    const newScan = () => {
        localStorage.clear()
        localStorage.setItem("isAutomated", true)
        localStorage.setItem("reloaded", true)
        generateInfo()
        openIssues()
    }


    return (
        <div className="h-full">
            <div className="h-1/2 flex flex-col items-center justify-center">
                <img
                    src={welcomeTeeth}
                    alt="welcome teeth"
                    className="saturation h-2/5"
                />
                <img
                    src={pepsodent_logo}
                    alt="logo"
                    className=" w-4/5"
                />
            </div>
            <div className="h-1/2 relative flex flex-col justify-center">
                <h5 className=" text-2xl  font-medium text-center mb-3">
                    Analisa awal kesehatan gigi
                </h5>
                <div className="text-lg font-medium text-center text-black opacity-60 mb-3 mx-8" >
                    Ambil 3 foto untuk mendapatkan analisa awal kesehatan gigi dengan teknologi AI
                </div>

                <div>
                    <div className="h-48  flex flex-col items-center px-4 py-2">
                        {

                            localStorage.getItem("isScanCompleted") ?
                                <p className=" text-xl text-green-600 font-medium text-center my-2">Anda telah berhasil menyelesaikan pemindaian Anda</p>
                                :
                                isInvalidUrl ? <p className="text-red-600 font-medium text-center">URL tidak valid</p> :

                                    <button
                                        type="button"
                                        className=" bg-red-unilever rounded text-white w-full h-11 font-semibold text-base mb-4"
                                        onClick={openIssues}
                                    >
                                        Mulai
                                    </button>
                        }

                    </div>
                </div>

                <div className="absolute bottom-5 w-full px-4">

                    <p className="flex items-center justify-center">
                        <span className="opacity-60"> Powered by </span>

                        <img
                            src={toothlensLogo}
                            alt="welcome teeth"
                            className=" ml-2 w-24"
                        />
                    </p>

                </div>
            </div>
        </div>
    )
}

export default LandingPage