import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import ToothSelector from "./toothSelector"
import Shoot from "./shoot"


const FullScan = () => {
    const navigate = useNavigate()
    const threePhotosSteps = [0, 3, 4]

    const { images } = useSelector((state) => state.fullScan);

    const [activeImageSide, setImageSide] = useState(images.length < 3 ? threePhotosSteps[images.length] : 0)
    const [isToothPicker, setToothPicker] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem("usertpi") || localStorage.getItem("isScanCompleted") || !localStorage.getItem("template")) {
            navigate("/unilever")
        }

        images.length >= 3 ? setToothPicker(true) : setToothPicker(false)


    }, [activeImageSide])




    return (
        <div className="h-full">
            {
                isToothPicker ?
                    <ToothSelector setImageSide={setImageSide} setToothPicker={setToothPicker} /> :
                    <Shoot activeImageSide={activeImageSide} setToothPicker={setToothPicker} quickScan={false} setImageSide={setImageSide} />
            }
        </div>
    )
}

export default FullScan