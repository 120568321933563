import { useState } from "react";
import doneDownload from "../../assets/bernImages/doneDownload.jpg";
import close from "../../assets/Icons/close.png"
import { downloadPdf } from "../../Services/APIs/report";
import { useNavigate } from "react-router-dom";
import toothlensLogo from "../../assets/illustrations/Toothlens Logo.jpeg"

const FThankYou = () => {
    const [DownloadStatus, setDownloadStatus] = useState("Download My Report");
    const navigate = useNavigate()


    const download = async () => {
        setDownloadStatus("Downloading...");
        const file = localStorage.getItem("pdf");
        if (file) {
            const bucketName = "toothlens-image-store"
            await downloadPdf({ file, bucketName })
                .then((response) => {
                    const url = response.data?.data?.info;
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = file; // Optional: Specify a download attribute
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDownloadStatus("Downloaded successfully");
                    setTimeout(() => {
                        setDownloadStatus("Download My Report");
                    }, 2500);
                })
                .catch((error) => {
                    setDownloadStatus("Failed, try again...");
                    setTimeout(() => {
                        setDownloadStatus("Download My Report");
                    }, 2500);
                });
        } else {
            setDownloadStatus("Not Found");
        }

    }

    return (
        <div>
            <div className="h-screen bg-custom-gradient text-center flex flex-col justify-center items-center px-3">
                <div className="absolute top-0 right-0 p-3">
                    <button type="button"
                        onClick={() => { navigate("/selfie") }} >
                        <img src={close} alt="close_img" width="26px" />

                    </button>
                </div>
                <h1
                    style={{
                        color: "#172433",
                        textAlign: "center",
                        fontFamilyt: "IvyMode",
                        fontWeight: "700",
                        fontSize: "24px",
                    }}
                >
                    Preparing your report
                </h1>
                <div className="mt-5 flex justify-center">
                    <img src={toothlensLogo} alt="done" />
                </div>
                <div className="mt-5 flex justify-center">
                    <div
                        style={{
                            color: "#2C3E50",
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: "16px",
                        }}
                    >
                        Your report will be ready shortly.
                    </div>
                </div>
                <div
                    className="flex justify-center "
                    style={{
                        color: "#2C3E50",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                    }}
                >
                    We will notify you via email or SMS once your report is
                    prepared.
                </div>
                <button
                    className=" text-lg bg-black text-white rounded w-full mx-3 my-10 py-2"
                    onClick={download}
                >
                    {DownloadStatus}
                </button>
            </div>
        </div>
    )
}

export default FThankYou