import { useState } from "react";
import { useNavigate } from "react-router-dom";
import pepsodent_logo from "../../../assets/unilever/Pepsodent Logo.png";
import close from "../../../assets/Icons/close.png"
import { downloadPdf } from "../../../Services/APIs/report";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";

const ThankYou = () => {
    const [DownloadStatus, setDownloadStatus] = useState("Unduh Analisa Dasar Saya");
    const navigate = useNavigate()


    const download = async () => {
        setDownloadStatus("Mengunduh...");
        const file = localStorage.getItem("pdf");
        if (file) {
            const bucketName = "unilever-image-store"
            await downloadPdf({ file, bucketName })
                .then((response) => {
                    const url = response.data?.data?.info;
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = file; // Optional: Specify a download attribute
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDownloadStatus("Berhasil diunduh");
                    setTimeout(() => {
                        setDownloadStatus("Unduh Analisa Dasar Saya");
                    }, 2500);
                })
                .catch((error) => {
                    setDownloadStatus("Gagal, coba lagi...");
                    setTimeout(() => {
                        setDownloadStatus("Unduh Analisa Dasar Saya");
                    }, 2500);
                });
        } else {
            setDownloadStatus("Not Found");
        }

    }

    return (
        <div>
            <div className="h-screen bg-custom-gradient text-center flex flex-col justify-center items-center px-3">
                <div className="absolute top-0 right-0 p-3">
                    <button type="button"
                        onClick={() => { navigate("/unilever") }} >
                        <img src={close} alt="close_img" width="26px" />

                    </button>
                </div>
                <h1
                    style={{
                        color: "#172433",
                        textAlign: "center",
                        fontFamilyt: "IvyMode",
                        fontWeight: "700",
                        fontSize: "24px",
                    }}
                >
                    Menyiapkan analisa dasar gigi kamu
                </h1>
                <div className="mt-5 flex justify-center">
                    <img src={pepsodent_logo} alt="done" className="w-3/4" />
                </div>
                <div className=" w-full px-4">

                    <p className="flex items-center justify-center">
                        <span className="opacity-60"> Powered by </span>

                        <img
                            src={toothlensLogo}
                            alt="welcome teeth"
                            className=" ml-2 w-24"
                        />
                    </p>

                </div>
                <div className="mt-10 flex justify-center">
                    <div
                        style={{
                            color: "#2C3E50",
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: "16px",
                        }}
                    >
                        Analisa dasar kesehatan gigi kamu sudah siap
                    </div>
                </div>
                <div
                    className="flex justify-center mt-3 "
                    style={{
                        color: "#2C3E50",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                    }}
                >
                    Konsultasikan dengan dokter gigi untuk mendapatkan rekomendasi perawatan
                </div>
                <p className="text-green-500 font-medium text-2xl text-center px-4 mt-4">Laporan Anda akan dikirim melalui WhatsApp.</p>
            </div>
        </div>
    )
}

export default ThankYou