
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addImages } from "../../../Store/fullScan"
import steps from "./steps"
import crop from "../../../Utils/crop"
import dataURItoBlob from "../../../Utils/dataUriToBlob"
import { createScan, imageUpload, submitScan } from "../../../Services/APIs/scan"
import ReshootPopup from "./reshootError"
import "../../../App.css"
import { clearImages } from "../../../Store/fullScan";

// import { addQuickScan, clearQuickScan } from "../../../indexStore/quickScan"
import SuccessScreen from "./submitSuccess"
import { fontStyle, fontWeight, companyBucket } from "../../../Utils/tpscanStyle"
import { clearReports } from "../../../Store/reports";
import { clearHealth } from "../../../Store/teethHealth";
import { clearQuestionnaire } from "../../../Store/questionnaire";
import { useNavigate, useParams } from "react-router-dom";

const Preview = (props) => {
    const { image, setCamera, setToothPicker, activeImageSide, setDemo, quickScan, setImageSide } = props
    const dispatch = useDispatch()
    const { quickScanS3Folder } = useSelector((state) => state.quickScan)
    const { fullScanS3Folder } = useSelector((state) => state.fullScan)
    const [reshoot, setReshoot] = useState(false)
    const [errorType, setImageError] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [quickScanLoading, setQuickScanLoading] = useState(false)
    const [isQuickScanTaken, setQuickScanTaken] = useState(false)
    const [isSuccess, setSuccess] = useState(false)
    const [isError, setError] = useState(false);
    const { selectedOptions, reason } = useSelector(
        (state) => state.questionnaire
      );
      const navigate = useNavigate();
      const { images} = useSelector((state) => state.fullScan);
      const [isSubmitted, setSubmitted] = useState(false);

    const next = async () => {
        const rotate = activeImageSide === 4        
        const croppedImage = await crop(image, 3 / 2, rotate).then(canvas => {
            return canvas.toDataURL("image/jpeg", 0.75);
        })
        await upload(croppedImage)
    }

    const upload = async (dataUri) => {
        const blob = dataURItoBlob(dataUri)
        const file = new File([blob], "report_image.jpeg", { type: "image/jpeg" });
        const formData = new FormData();
        formData.append("report_image", file);
        setLoading(true)
        setReshoot(false)
        const folderName = quickScan ? quickScanS3Folder : fullScanS3Folder
        await imageUpload({ formData, folderName }).then((response) => {
            setLoading(false)

            const required = {
                title: steps[activeImageSide].title,
                dataUri: URL.createObjectURL(blob),
                file: response.data.data?.imageName,
                isReshoot: response.data.data?.is_reshoot,
                mouth_detection_message: response.data.data?.mouth_detection_message
            }
            if (required.isReshoot) {
                setReshoot(true)
                if (response.data.data?.mouth_detection_message === "mouth far away") {
                    setImageError("mouth far away")
                } else if (response.data.data?.mouth_detection_message === "blur detected") {
                    setImageError("blur detected")
                }
                else {
                    setImageError("general")
                }
            } else {
                if (quickScan) {
                    // dispatch(addQuickScan(required))
                    setQuickScanTaken(true)


                } else {
                    dispatch(addImages(required))
                    submit()

                    if (localStorage.getItem("isAutomated") && activeImageSide < 4) {
                            // setImageSide(activeImageSide + 1)
                            // setDemo(true)
                            console.log("came inside")
                            submit()
                        } else {
                            setToothPicker(true)
                        }

                }

            }

        }).catch((error) => {
            setLoading(false)
            setReshoot(true)
            setImageError("failed")
        })


    }

    const submit = async () => {
        setLoading(true);
        await submitScan({ folderName: fullScanS3Folder })
          .then(async (response) => {
            if (localStorage.getItem("usertpi")) {
              localStorage.setItem(
                "tpscore",
                JSON.stringify(response.data?.data?.data)
              );
            }
            const { images, ...mlResults } = response?.data?.data?.data;
            const { pdf_url, name, ...report_score } = mlResults;
            localStorage.setItem("pdf", `${name}.pdf`);
            createReport({ pdf_url, report_score });
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
          });
      };
    
      const createReport = async ({ pdf_url, report_score }) => {
        const tooth = {
          front_teeth: "Front teeth",
          upper_jaw: "Upper jaw",
          lower_jaw: "Lower jaw",
          left_side: "Left side",
          right_side: "Right side",
        };
        const report = {
          report_score,
          pdf_url,
          is_full_scan: true,
          user_looking_for: selectedOptions,
          reason,
        };
        for (const key in tooth) {
          images.forEach((eachObj) => {
            if (tooth[key] === eachObj.title) {
              report[key] = eachObj.file;
            }
          });
        }
    
        await createScan(report)
          .then((response) => {
            localStorage.setItem("response", JSON.stringify(response.report));
            dispatch(clearImages());
            dispatch(clearReports());
            dispatch(clearHealth());
            dispatch(clearQuestionnaire());
            localStorage.setItem("scoreCard", true);
            localStorage.setItem("isScanCompleted", true)
            localStorage.removeItem("reloaded")
             navigate("/pg/thanks")
        
          })
          .catch((error) => {
            setLoading(false);
            setError(true);
          });
      };

    return (
        <>
            {
                isSuccess ? <SuccessScreen isFullScan={false} /> :

                    <div className="h-full">
                        <div className="relative top-0 h-full w-full">
                            <img src={image} alt="preview" className={`w-full h-full  ${activeImageSide === 4 && "ransform rotate-180 "}`} />
                        </div>

                        {
                           
                                <div className="z-10 absolute bottom-5 w-full flex justify-evenly">
                                    <button
                                        style={{backgroundColor:"#1E1E78"}}
                                        className="  w-1/3 rounded-full  text-white py-2 px-4 shadow-2xl  font-bold "
                                        onClick={() => { setCamera(true) }}>
                                        <span style={{ ...fontStyle, ...fontWeight }}>撮り直す
                                        </span> </button>
                                    <button
                                     style={{backgroundColor:"#1E1E78"}}
                                     className=" w-1/3 rounded-full  text-white py-2 px-4 shadow-2xl  font-bold "
                                     onClick={next} disabled={isLoading}

                                    >
                                        {
                                            isLoading ? <div className="loader-container">
                                                <div className="loader dark-loader"></div>
                                            </div> : <span style={{ ...fontStyle, ...fontWeight }}>OK</span>
                                        }
                                    </button>
                                </div>
                        }

                        {reshoot && <ReshootPopup setReshoot={setReshoot} errorType={errorType} setCamera={setCamera} setDemo={setDemo} />}
                    </div>
            }
        </>
    )
}


export default Preview