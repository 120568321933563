import { Route, Routes } from "react-router-dom"
import LandingPage from "./PgLandingPage/landingPage"
import FullScan from "./PgScan/fullScan"
import ThankYou from "./PgThanks/thanks"
import PageNotFound from "./PageNotFound/pageNotFound"
const Pg = () => {
    return (
        <div className="h-full">
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="scan" element={<FullScan />} />
                <Route path="thanks" element={<ThankYou />} />

                {/* <Route path="looking-for" element={<LookingFor />} />
                <Route path="scan" element={<FullScan />} />
                <Route path="single-scan" element={<SingleScan />} />
                <Route path="thanks" element={<ThankYou />} />
                <Route path="i-thanks" element={<IPadThanks />} />
                <Route path=":ph_no" element={<ReadPhNo />} /> */}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    )
}

export default Pg