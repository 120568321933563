import front from "../../../assets/unilever/front_demo.png"
import upperJaw from "../../../assets/unilever/upper_demo.png"
import lowerJaw from "../../../assets/unilever/lower_demo.png"
import left from "../../../assets/demoImages/left.png"
import right from "../../../assets/demoImages/right.png"

const steps = [
    {
        title: "Tampak depan gigi",
        image: front,
        tips: ["Pastikan tampak depan gigi berada dalam garis berwarna merah", "Sisakan sedikit ruang antar gigi atas dan bawah"]
    },
    {
        title: "Left side",
        image: right,
        tips: ["Pull the corner of your mouth as back as possible"],
    },
    {
        title: "Right side",
        image: left,
        tips: ["Pull the corner of your mouth as back as possible"]
    },
    {
        title: "Rahang bawah",
        image: lowerJaw,
        tips: ["Buka mulut dan tundukkan kepala sedikit", "Pastikan rahang bawah tidak melewati garis Merah"]
    },
    {
        title: "Rahang atas",
        image: upperJaw,
        tips: ["Open your mouth tilt your head backwards", "Fit your teeth within the template."]
    }
]


export default steps
