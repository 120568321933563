import { useState } from "react";
import { useNavigate } from "react-router-dom";
import pepsodent_logo from "../../../assets/unilever/Pepsodent Logo.png";
import close from "../../../assets/Icons/close.png"
import { downloadPdf } from "../../../Services/APIs/report";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
const ThankYou = () => {
    const [DownloadStatus, setDownloadStatus] = useState("レポートをダウンロード");
    const navigate = useNavigate()


    const download = async () => {
        setDownloadStatus("ダウンロード中...");
        const file = localStorage.getItem("pdf");
        if (file) {
          const bucketName = JSON.parse(
            localStorage.getItem("response")
          ).bucket_name;
          await downloadPdf({ file, bucketName: "pg-image-store" })
            .then((response) => {
              const url = response.data?.data?.info;
              const link = document.createElement("a");
              link.href = url;
              link.download = file; // Optional: Specify a download attribute
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setDownloadStatus("ダウンロード済み");
              setTimeout(() => {
                setDownloadStatus("レポートをダウンロード");
              }, 2500);
            })
            .catch((error) => {
              setDownloadStatus("失敗した");
              setTimeout(() => {
                setDownloadStatus("レポートをダウンロード");
              }, 2500);
            });
        } else {
          setDownloadStatus("見つかりません");
        }
      };

    return (
        <div>
            <div className="h-screen bg-custom-gradient text-center flex flex-col justify-center items-center px-3">
                <div className="absolute top-0 right-0 p-3">
                    <button type="button"
                        onClick={() => { navigate("/pg") }} >
                        <img src={close} alt="close_img" width="26px" />

                    </button>
                </div>
                <h1
                    style={{
                        color: "#172433",
                        textAlign: "center",
                        fontFamilyt: "IvyMode",
                        fontWeight: "700",
                        fontSize: "24px",
                    }}
                >
                レポート作成中                 </h1>
                <div className="mt-5 flex justify-center">
                    <img src="https://toothlens.com/wp-content/uploads/2022/02/cropped-Toothlens_Logo.png" alt="done" className="w-3/4" />
                </div>
                
                <div className="mt-10 flex justify-center">
                    <div
                        style={{
                            color: "#2C3E50",
                            fontFamily: "Poppins",
                            fontWeight: "700",
                            fontSize: "14px",
                        }}
                    >
              以下よりチェックレポートがダウンロードできます
                </div>
                </div>
                <div className="  w-full px-5 pb-3  rounded ">

                  
        {DownloadStatus === "Downloaded" ? (
          <p
            className="py-3 bg-green-100 rounded text-center font-semibold text-green-900"
          >
            Downloaded successfully
          </p>
        ) : (
          <button 
          onClick={()=>{
              download()
          }}
           style={{backgroundColor:"#1E1E78"}}
           className="  w-2/3 rounded-full border-2 text-white py-2 px-4 shadow-2xl mt-8  font-bold ">
          
            {DownloadStatus}</button>
        )}
      </div>  
              
            </div>
         
        </div>
    )
}

export default ThankYou